import React from "react";
import DineroImage from '../../images/Dinero.jpg';


const LandingPage = () => {
  return (

    <div className="landing-page-container" data-testid="landing-page">
      <section id="Enter" className="hero-section">

        {/* TODO Banner and Button for Entering */}
        <div id="Hero" className="hero-content">
          <h1>Participa</h1><h1>y</h1>
          <h1>Gana!</h1>
          <h3>Entra en la rifa ahora para participar.</h3>
          <p>Reserva tus boletos, presiona el boton para reservar.</p>
        </div>
        <button className="hero-button" type="button">
          <a href="#Entra">Participa</a>
        </button>
      </section>


      <div id="Informacion" className="sections-container">
        <section className="information-section">
          <h2>Informacion</h2>
          <img src={DineroImage} alt="Descriptive Alt Text" className="info-image" />
          <br></br>
          <p>Participar es muy facil</p>
          <br></br>
          <p>Precio: $100</p>
          <br></br>
          <p>Ganadores se llevaran los siguientes premios</p>
          <ol>
          <br></br>
            <li>$50,000</li>
            <li>$5,000</li>
            <li>$5,000</li>
          </ol>
          <p>NOTA: Sorteo sujeto a la venta de el 90% de los boletos.</p>
          <p>Se juega en base a lotería nacional últimas 3 cifras de el premio mayor Recuerden que después de apartar susboletos y hacer el pago</p>
          <p>esperamos un mensaje con su nombre y foto de el comprobante de pago o foto de su transferencia</p>
          <p>100 pesos el boleto equivalente a 7 dólares paga como gustes.</p>
          <div className="social-icons">
            {/* Social icons here */}
          </div>
        </section>

        <section id="Participa" className="about-section">
          <h2>Como participar</h2>
          <br></br>
          <p>CUENTAS PARA TRANFERENCIA Y PAGO EN TIENDA:</p>
          <br></br>
          <ol>
            <li><p>$100 pesos el 🎟️ </p>
              <br></br>
              <p>BBVA: Nombre:Carlos Vazquez Vazquez. TARJETA: 4152 3140 7868 8440 Cuenta: 157 901 6028 Clave: 0122 1501 5790 160280</p>
              <br></br>
              <p>MERCADO PAGO: Beneficiario: Carlos Vazquez vazquez. Clave: 722969010640378158</p>
              <br></br>
              <p>BANCOPPEL: Nombre: Carlos Vazquez Vazquez. Tarjeta: 4169 1608 3663 8123 Cuenta: 104 4887 1652 Clave: 137210104488716520 </p></li>
            <li><p>$7 dólares el 🎟️ ZELLE TRANFERENCIA DOLLAR karlovaz_801@yahoo.com</p></li>
            <li><p>$7 dólares el 🎟️ CASHAPP DOLLAR NAME: $karlovaz </p></li>
          </ol>
          <p>NOTA: en la compra de 4 boletos o mas bono de $ 5,000 resultando ganador. poner nombre en concepto de pago. Recuerda enviar la foto de tu transferencia o ticket poner nombre en ticket. RIFA SUJETA AL 90% DE LOS BOLETOS</p>
          <div className="social-icons">
            {/* Social icons here */}
          </div>
        </section>

        <section id="Entra" className="raffle-section">
          <h2>Enter Raffle</h2>
          <iframe
            src="https://rifa-73864.web.app/home?id=8ZZItlpTgTjAaOROFNKn"
            frameborder="0"
            allowfullscreen>
          </iframe>
        </section>
      </div>
    </div>

  );
};

export default LandingPage;
